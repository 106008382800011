import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

import axios from 'axios';
import { PrivateRoute, config, authHeader } from './helpers';
import { ToastsContainer, ToastsStore } from 'react-toasts';

const ProfileData = lazy(() => import('./profile-data/ProfileData'));
const EmailData = lazy(() => import('./profile-data/components/EmailData'));
const MindData = lazy(() => import('./profile-data/components/MindData'));
const PerfectGym = lazy(() => import('./profile-data/components/PerfectGym'));
const FitDegree = lazy(() => import('./profile-data/components/FitDegree'));
const GymMaster = lazy(() => import('./profile-data/components/GymMaster'));
const Exerp = lazy(() => import('./profile-data/components/Exerp'));
const Eway = lazy(() => import('./profile-data/components/Eway'));
// const SingleProfileData = lazy(() => import('./single-data/SingleProfileData'));

const Dashboard = lazy(() => import('./dashboard/dashboard'));
const UserList = lazy(() => import('./admin/userlist'));
const SingleData = lazy(() => import('./admin/components'));
const AdminProfileData = lazy(() => import('./admin/components/ProfileData'));
const AdminZiplineData = lazy(() => import('./admin/components/Zipline'));
const AdminMindData = lazy(() => import('./admin/components/MindData'));
const AdminPerfectGym = lazy(() => import('./admin/components/PerfectGym'));
const AdminFitDegree = lazy(() => import('./admin/components/FitDegree'));
const AdminGymMaster = lazy(() => import('./admin/components/GymMaster'));
const AdminExerp = lazy(() => import('./admin/components/Exerp'));

const Error404 = lazy(() => import('./user-pages/Error404'));
const Error500 = lazy(() => import('./user-pages/Error500'));

const AdminLogin = lazy(() => import('./user-pages/Login'));
const GetLogin = lazy(() => import('./user-pages/GetLogin'));
const Register1 = lazy(() => import('./user-pages/Register'));

const BlankPage = lazy(() => import('./user-pages/BlankPage'));


class AppRoutes extends Component {
  constructor() {
		super();
		this.state = {
      authenticated: false,
			currentUser: null,
			token: '',
      options: {},
		}
  }

  componentDidMount() {
		let user = JSON.parse(localStorage.getItem('zlUsr'));
		if( user && user.auth_token) {
      // console.log("asdsd");
			//const isAdmin = user.data.role_id === 1 ? true : false
			this.setState({ authenticated:true, currentUser:user.data })
			// console.log(user);
		}else{
			//this.props.history.push('/login')
		}
		// console.log(user);
  }
  
  setCurrentUser(user) {
		if(user) { // console.log(user);
			this.setState({ 
				currentUser: user,
				authenticated: true
			 })
		}
		else {
			this.logout();
			
		}
  }
  
  logout = () =>{
    let mounted = true;
    const lgt = () => {
    
    axios({
        method: 'get',
        url: `${config.apiUrl}user/logout`,
        headers: { 'Content-Type' : 'application/json', ...authHeader() },
    })    
    .then(response => {
        if (mounted) {
            // console.log(response)
        if(response.data.status === 200){
            // ToastsStore.success(response.data.message)
            localStorage.removeItem('zlUsr');
        }else{
            ToastsStore.error(response.data.message)
        }
        }
    }).catch(error => {
        if(error.response && error.response.status === 401) {
            ToastsStore.error(error.response.statusText)
            localStorage.removeItem('zlUsr');
            this.setState({
              currentUser: null,
              authenticated: false,
              token: ''
            })
        }
        if(error.response){
            ToastsStore.error(error.response.statusText)
        }else{
            ToastsStore.error(error.message)
        }
    });
    };
    lgt();
    return () => {
    mounted = false;
    };
  }

	handleState(data) {
		this.setState({ [data.key]: data.value })
	}

	actions = () => {
		return {
			setCurrentUser: this.setCurrentUser.bind(this),
			//handleSaveDesign: this.handleSaveDesign.bind(this),
			handleState: this.handleState.bind(this),
		}
  }
  
  render () {
    // console.log(this.props);
    return (
      <>
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/profile-data/:token/:location" component={ ProfileData } />
          <Route exact path="/profile-data/:token/:location/:type" component={ EmailData } />
          <Route exact path="/profile-data/:token/:location/mindbody/data" component={ MindData } />
          <Route exact path="/profile-data/:token/:location/perfectgym/data" component={ PerfectGym } />
          <Route exact path="/profile-data/:token/:location/fitdegree/data" component={ FitDegree } />
          <Route exact path="/profile-data/:token/:location/gymmaster/data" component={ GymMaster } />
          <Route exact path="/profile-data/:token/:location/exerp/data" component={ Exerp } />
          <Route exact path="/profile-data/:token/:location/eway/data" component={ Eway } />
          {/* <Route exact path="/profile-data/:token/:type" component={ EmailData } /> 
          <Route exact path="/single-profile-data/:token/:locationid" component={ SingleProfileData } />*/}
          <PrivateRoute exact path="/dashboard" component={()=> <Dashboard {...this.state} actions={this.actions()}/> } />
          <PrivateRoute exact path="/user-list" component={()=> <UserList {...this.state} {...this.props} actions={this.actions()}/> } />
          {/* <PrivateRoute exact path="/user-list/:usr" component={()=> <SingleData {...this.state} actions={this.actions()}/> } /> */}
          <PrivateRoute exact path="/user-list/:nme/:usr" component={SingleData} />
          <PrivateRoute exact path="/user-list/:nme/:usr/:location" component={AdminProfileData} />
          <PrivateRoute exact path="/user-list/:nme/:usr/:location/zipline/data" component={AdminZiplineData} />
          <PrivateRoute exact path="/user-list/:nme/:usr/:location/mindbody/data" component={AdminMindData} />
          <PrivateRoute exact path="/user-list/:nme/:usr/:location/perfectgym/data" component={AdminPerfectGym} />
          <PrivateRoute exact path="/user-list/:nme/:usr/:location/fitdegree/data" component={AdminFitDegree} />
          <PrivateRoute exact path="/user-list/:nme/:usr/:location/gymmaster/data" component={AdminGymMaster} />
          <PrivateRoute exact path="/user-list/:nme/:usr/:location/exerp/data" component={AdminExerp} />


          <Route path="/auth/zl-admin/login" component={()=> <AdminLogin {...this.state} actions={this.actions()}/> } />
          <Route path="/auth/getlogin" component={()=> <GetLogin {...this.state} actions={this.actions()}/> } />
          <Route path="/user-pages/register-1" component={ Register1 } />

          <Route path="/user-pages/error-404" component={ Error404 } />
          <Route path="/user-pages/error-500" component={ Error500 } />

          <Route path="/user-pages/blank-page" component={ BlankPage } />

          <Redirect to="/user-list" />
        </Switch>
      </Suspense>

      <ToastsContainer store={ToastsStore}/>
      </>
    );
  }
}

export default AppRoutes;